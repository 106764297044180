'use strict';

angular.module('enervexSalesappApp').controller('BomRuleCtrl', function($scope, BomRule, $stateParams, Product, Flash, $state, Fan, Control, _, Util, BomRuleCategory, Voltage, VentMaterial, BaffleType, ModType, Baffle, Manufacturer) {
	$scope.errors = {};
	$scope.categories = [];
	Manufacturer.query().$promise.then(function(result){
		$scope.manufacturers = result
		$scope.manufacturer = _.find(result, function(o){
			return o.isDefault
		})
	})

	if ($stateParams.id == "new") {
		if ($stateParams.copyId){
			BomRule.get({
				id: $stateParams.copyId
			}).$promise.then(function(bomRule) {
				$scope.bomRule = _.clone(_.omit(bomRule, "_id", "__v","$promise","$resolved"));
				$scope.ctx.formChanged = true;
				fetchLookups()
			});
		} else {
			$scope.bomRule = {
				conditionType:'selected',
				actionType: 'product',
				active: true,
				condition:{
					exhFansLogic:'none'
				}
			};
			fetchLookups()
		}
	} else {
		BomRule.get({
			id: $stateParams.id
		}).$promise.then(function(bomRule) {
			$scope.bomRule = bomRule;
			fetchLookups()
		});
	}
	$scope.ctx = {
		formChanged:false
	}
	$scope.parse = function($event) {
		$event.preventDefault();
		Util.parseBomFilter($scope.bomRule.filter, $scope.bomRule.condition)
		$scope.bomRule.conditionType = 'selected';
		$scope.ctx.formChanged = true;
		fetchLookups()
	}
	$scope.selectCategory = function(){
		var category = _.find($scope.categories,function(c){
			return c._id == $scope.bomRule.category;
		})
		$scope.category = category;
	}
	function fetchLookups(){
		Fan.query({
			limit:3000
		}).$promise.then(function(_fans){
			var fans = _.sortBy(_.uniq(_.pluck(_fans, "code")), function(code){
				return code
			})
			$scope.exhFans = _.map(fans, function(original){
				var fan = {
					code: original,
					selected: false
				}
				var existing = _.find($scope.bomRule.condition.exhFans, function(f){
					return f == fan.code
				})
				if (existing){
					fan.selected = true
				}
				return fan
			});
			$scope.supFans = _.map(fans, function(original){
				var fan = {
					code: original,
					selected: false
				}
				var existing = _.find($scope.bomRule.condition.supFans, function(f){
					return f == fan.code
				})
				if (existing){
					fan.selected = true
				}
				return fan
			});
			var computeAses = [
				{
					code: "COM"
				},{
					code: "BWH"
				}, {
					code: "DRY"
				}
			];
			var ventDiameters = [2,3,4,5,6,7,8,9,10,12,14,16,18,20,22,24,26,28, 30, 32, 34, 36,38, 40 , 42, 44, 46];
			$scope.ventDiameters = _.map(ventDiameters, function(d){
				var existing = _.find($scope.bomRule.condition.ventDiameters, function(_d){
					return _d == d
				})
				return {
					value: d,
					selected: (existing) ? true: false
				}
			})
			$scope.damperDiameters = _.map(ventDiameters, function(d){
				var existing = _.find($scope.bomRule.condition.damperDiameters, function(_d){
					return _d == d
				})
				return {
					value: d,
					selected: (existing) ? true: false
				}
			})
			$scope.computeAses = _.map(computeAses, function(computeAs){
				var existing = _.find($scope.bomRule.condition.computeAses, function(v){
					return v == computeAs.code
				})
				if (existing){
					computeAs.selected = true
				}
				return computeAs
			})
		});
		Voltage.query().$promise.then(function(voltages){
			$scope.voltages = _.map(voltages, function(voltage){
				var existing = _.find($scope.bomRule.condition.voltages, function(v){
					return v == voltage.code
				})
				if (existing){
					voltage.selected = true
				}
				return voltage
			})
		})
		Control.query().$promise.then(function(controls){
			$scope.controls = _.map(controls, function(control){
				var existing = _.find($scope.bomRule.condition.controls, function(c){
					return c == control.name
				})
				if (existing){
					control.selected = true
				}
				return control
			});
		});
		VentMaterial.query().$promise.then(function(materials){
			$scope.ventMaterials = _.map(materials, function(o){
				var material = _.clone(o);
				var existing = _.find($scope.bomRule.condition.ventMaterials, function(c){
					return c == material.code
				})
				if (existing){
					material.selected = true
				}
				return material
			});
			$scope.endpointMaterials = _.map(materials, function(o){
				var material = _.clone(o);
				var existing = _.find($scope.bomRule.condition.endpointMaterials, function(c){
					return c == material.code
				})
				if (existing){
					material.selected = true
				}
				return material
			});
			$scope.damperMaterials = _.map(materials, function(o){
				var material = _.clone(o);
				var existing = _.find($scope.bomRule.condition.damperMaterials, function(c){
					return c == material.code
				})
				if (existing){
					material.selected = true
				}
				return material
			});
		})
		Baffle.query().$promise.then(function(baffles){
			$scope.baffles = _.map(baffles, function(baffle){
				var existing = _.find($scope.bomRule.condition.baffles, function(c){
					return c == baffle.code
				})
				if (existing){
					baffle.selected = true
				}
				return baffle
			})
		})
		BaffleType.query().$promise.then(function(baffleTypes){
			$scope.baffleTypes = _.map(baffleTypes, function(baffleType){
				var existing = _.find($scope.bomRule.condition.baffleTypes, function(c){
					return c == baffleType.code
				})
				if (existing){
					baffleType.selected = true
				}
				return baffleType
			});
		})
		ModType.query().$promise.then(function(modTypes){
			$scope.modTypes = _.map(modTypes, function(modType){
				var existing = _.find($scope.bomRule.condition.modTypes, function(c){
					return c == modType.code
				})
				if (existing){
					modType.selected = true
				}
				return modType
			});
		})
		BomRuleCategory.query().$promise.then(function(categories){
			$scope.categories = categories;
			$scope.selectCategory();
		})
	}
	$scope.selectedLogics = [
		'selected',
		'not selected'
	]
	$scope.conditionTypes = [
		"javascript",
		"selected",
		"baffleMatcher",
		"economizerMatcher"
	];
	$scope.actionTypes = [
		"javascript",
		"product"
	];

	$scope.computeAses = [
		"COM",
		"BWH",
		"DRY"
	];
	$scope.fanLogics = [
		'none',
		'not none',
		'selected',
		'not selected'
	];
	$scope.voltagesLogics = $scope.selectedLogics;
	$scope.controlsLogics = $scope.selectedLogics;
	$scope.computeAsesLogics = $scope.selectedLogics;
	$scope.ventDiametersLogics = $scope.selectedLogics;
	$scope.damperDiametersLogics = $scope.selectedLogics;
	$scope.shapes = [
		"ROU",
		"SQU",
		"REC",
		"OVA"
	];
	$scope.conditionLogics = ['==', '>', '<', '<=', '>='];
	$scope.existsLogic = ['==', '!='];
	$scope.booleanOptions = [
		{
			name: "true",
			value: true
		},
		{
			name: "false",
			value: false
		}
	]
	$scope.autoCompleteOptions = {
		minimumChars: 1,
		dropdownWidth: "500px",
		data: function (term) {
			return Product.query({
				limit:20,
				term: term,
				manufacturer: $scope.manufacturer._id
			}).$promise.then(function(products){
				$scope.productsPage = products
				return _.map(products, function(product){
					return product.navId + " - " + product.name + " " + accounting.formatMoney(product.price)
				});
			})
		},
		itemSelected: function(item){
			$scope.ctx.formChanged = true;
			var fullProduct = _.find($scope.productsPage, function(product){
				return (product.navId + " - " + product.name + " " + accounting.formatMoney(product.price)) == item.item
			})
			$scope.bomRule.product = fullProduct;
			$scope.bomRule.legacyId = fullProduct.navId;
		}
	}
	// $scope.categories = [
	// 	"exhaust-adapter", 
	// 	"exhaust-baffle", 
	// 	"exhaust-control", 
	// 	"exhaust-controls",
	// 	"exhaust-damper", 
	// 	"exhaust-fan", 
	// 	"exhaust-mprbox", 
	// 	"exhaust-prt2000", 
	// 	"exhaust-relay-box", 
	// 	"exhaust-relay-module", 
	// 	"new-adapter", 
	// 	"plenum-boxes", 
	// 	"supply-adapter",
	// 	"supply-control", 
	// 	"supply-controls", 
	// 	"supply-fan", 
	// 	"supply-pressure-transducer", 
	// 	"supply-prt2000", 
	// 	"supply-single-phase",
	// 	"supply-static-pressure-sensor"
	// ];

	function guardBoolean(object, attribute){
		var val = object[attribute];
		if (val !== true && val !== false){
			object[attribute] = null
		}
	}
	function guardString(object, attribute){
		if (object[attribute] == ''){
			object[attribute] = null
		}
	}
	function guardNumber(object, attribute){
		if (object[attribute] && object[attribute] != ''){
			object[attribute] = parseFloat(object[attribute])
		}
	}
	$scope.save = function(form) {
		$scope.submitted = true;
		if (form.$valid) {
			Flash.clear();
			var payload = _.clone($scope.bomRule);
			payload = _.omit(payload, "__v");
			if (payload.condition.exhFansLogic == 'selected' || payload.condition.exhFansLogic == 'not selected'){
				payload.condition.exhFans = _.pluck(_.where($scope.exhFans, {selected:true}), "code")
			} else {
				payload.condition.exhFans = []
			}
			guardNumber(payload.condition, "exhFanQuan")
			guardString(payload.condition, "exhFanQuanLogic")

			if (payload.condition.supFansLogic == 'selected' || payload.condition.supFansLogic == 'not selected'){
				payload.condition.supFans = _.pluck(_.where($scope.supFans, {selected:true}), "code")
			} else {
				payload.condition.supFans = []
			}
			guardNumber(payload.condition, "supFanQuan")
			guardString(payload.condition, "supFanQuanLogic")

			guardNumber(payload.condition, "combustionAppliancCount")
			guardString(payload.condition, "combustionAppliancCountLogic")

			if (payload.condition.voltagesLogic == 'selected' || payload.condition.voltagesLogic == 'not selected'){
				payload.condition.voltages = _.pluck(_.where($scope.voltages, {selected:true}), "code")
			} else {
				payload.condition.voltages = []
			}
			if (payload.condition.baffleTypesLogic == 'selected' || payload.condition.baffleTypesLogic == 'not selected'){
				payload.condition.baffleTypes = _.pluck(_.where($scope.baffleTypes, {selected:true}), "code")
			} else {
				payload.condition.baffleTypes = []
			}
			if (payload.condition.bafflesLogic == 'selected' || payload.condition.bafflesLogic == 'not selected'){
				payload.condition.baffles = _.pluck(_.where($scope.baffles, {selected:true}), "code")
			} else {
				payload.condition.baffles = []
			}
			if (payload.condition.modTypesLogic == 'selected' || payload.condition.modTypesLogic == 'not selected'){
				payload.condition.modTypes = _.pluck(_.where($scope.modTypes, {selected:true}), "code")
			} else {
				payload.condition.modTypes = []
			}
			if (payload.condition.computeAsesLogic == 'selected' || payload.condition.computeAsesLogic == 'not selected'){
				payload.condition.computeAses = _.pluck(_.where($scope.computeAses, {selected:true}), "code")
			} else {
				payload.condition.computeAses = []
			}
			if (payload.condition.controlsLogic == 'selected' || payload.condition.controlsLogic == 'not selected'){
				payload.condition.controls = _.pluck(_.where($scope.controls, {selected:true}), "name")
			} else {
				payload.condition.controls = []
			}
			if (payload.condition.ventDiametersLogic == 'selected' || payload.condition.ventDiametersLogic == 'not selected'){
				payload.condition.ventDiameters = _.pluck(_.where($scope.ventDiameters, {selected:true}), "value")
			} else {
				payload.condition.ventDiameters = []
			}
			if (payload.condition.damperDiametersLogic == 'selected' || payload.condition.damperDiametersLogic == 'not selected'){
				payload.condition.damperDiameters = _.pluck(_.where($scope.damperDiameters, {selected:true}), "value")
			} else {
				payload.condition.damperDiameters = []
			}
			if (payload.condition.ventMaterialsLogic == 'selected' || payload.condition.ventMaterialsLogic == 'not selected'){
				payload.condition.ventMaterials = _.pluck(_.where($scope.ventMaterials, {selected:true}), "code")
			} else {
				payload.condition.ventMaterials = []
			}
			if (payload.condition.endpointMaterialsLogic == 'selected' || payload.condition.endpointMaterialsLogic == 'not selected'){
				payload.condition.endpointMaterials = _.pluck(_.where($scope.endpointMaterials, {selected:true}), "code")
			} else {
				payload.condition.endpointMaterials = []
			}
			if (payload.condition.damperMaterialsLogic == 'selected' || payload.condition.damperMaterialsLogic == 'not selected'){
				payload.condition.damperMaterials = _.pluck(_.where($scope.damperMaterials, {selected:true}), "code")
			} else {
				payload.condition.damperMaterials = []
			}

			guardBoolean(payload.condition, "exhSinglePhase")
			guardNumber(payload.condition, "endpointDiameter")
			guardBoolean(payload.condition, "constantVol")

			guardNumber(payload.condition, "minAppliances")
			guardNumber(payload.condition, "maxAppliances")

			guardString(payload, "damperShape")
			guardString(payload, "endpointShape")

			guardBoolean(payload.condition, "condensing")
			guardBoolean(payload.condition, "damper")
			guardBoolean(payload.condition, "hasDamperSection")

			if (!$scope.bomRule._id) {
				BomRule.save(payload).$promise.then(function(result) {
					
					$scope.success = true;
					$scope.bomRule = result;
					Flash.create('success', '<strong>Success!</strong> Successfully created rule.', 0, {}, true);
					$state.go("bom-rule", {
						id: result._id,
						copyId: null
					}, {
						inherit: false,
						reload: true
					})
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			} else {
				BomRule.update({
					id: payload._id
				}, payload).$promise.then(function(result) {
					
					$scope.success = true;
					$scope.bomRule = result;
					Flash.create('success', '<strong>Success!</strong> Successfully updated rule.', 0, {}, true);
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			}
		} else {
			
			$scope.errors = {};
			$scope.errors.other = 'Problem saving';
			var message = "Please fix validation errors";
			Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
		}
	};
});